import React from 'react';
import Main from '../containers/Layout';
import dropshippingContent from '../../content/pages/dropshipping.yml';
import DropshippingContainer from '@/containers/toolkit/DropshippingContainer';

export interface DropshippingPageContent {
  viewport1: {
    title1: string;
    title2: string;
    description: string;
    button: {
      label: string;
      url: string;
    };
  };
  viewport2: {
    title: string;
    carouselItems: {
      title: string;
      description: string;
    }[];
  };
  viewport3: {
    cards: {
      title: string;
      tickedDescription: { item: string }[];
      image: string;
    }[];
  };
  viewport4: {
    label: string;
    button: {
      label: string;
      url: string;
    };
  };
}

const Dropshipping: React.FunctionComponent = () => {
  const { viewport1, viewport2, viewport3, viewport4 } =
    dropshippingContent as unknown as DropshippingPageContent;
  return (
    <Main>
      <DropshippingContainer
        content={{
          viewport1,
          viewport2,
          viewport3,
          viewport4,
        }}
      />
    </Main>
  );
};

export default Dropshipping;
